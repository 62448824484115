
    import Quiz from "@/components/quiz.vue";
    import { Component, Vue } from "vue-property-decorator";
    import { IQuizInterface } from '../../ts/quiz/IQuizInterface';
    import { GraphUtils } from '../../ts/utils/GraphUtils';

    @Component({
        components: {
            Quiz
        },
    })

    // *****************************************************************************************************************
    export default class DevTypeScript extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------
        private readonly languagesGraph = {
            // tslint:disable
            traces: [
                {
                    x: ["2016-06-01", "2017-06-01", "2018-06-01", "2019-06-01"],
                    y: [55.4, 62.5, 69.8, 67.8],
                    mode: 'lines+markers',
                    marker: {
                        color: 'hsl(0, 98%, 41%)',
                        size: 9
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(0, 50%, 60%)',
                        width: 3
                    },
                    name: 'JavaScript',
                    text: ['%', '%', '%', '%']
                },
                {
                    x: ["2016-06-01", "2017-06-01", "2018-06-01", "2019-06-01"],
                    y: [0.47, 9.5, 17.4, 21.2],
                    mode: 'lines+markers',
                    marker: {
                        color: 'hsl(205, 98%, 41%)',
                        size: 9
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(204, 50%, 60%)',
                        width: 3
                    },
                    name: 'TypeScript',
                    text: ['%', '%', '%', '%'],                        
                }
            ],
            // tslint:enable
        };

        private readonly toolsGraph = {
            // tslint:disable
            traces: [
                {
                    type:           'pie',
                    rotation:       0,
                    values:         [50.7, 31.5, 30.5, 25.4, 25.4],
                    marker:         { colors: ["#3D99D4", "#5C2D91", "#75D66F", "#FA2F5E", "#CCCCCC"] },
                    labels:         ['Visual Studio<br>Code', 'Visual Studio', 'Notepad++', 'IntelliJ', 'Vim'],
                    textinfo:       "label+value",
                    hoverinfo:      "label+value",
                    textposition:   "outside",
                    automargin:     true,
                    showlegend:     false
                }
            ],
            // tslint:enable
        };

        // Methods -----------------------------------------------------------------------------------------------------
        private get createLanguageLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "Popularity Percentage", 72, 32);
            // tslint:enable
        }

        private get createToolsLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "", 72, 16);
            // tslint:enable
        }

        private data() {
            return {
                javaScriptIconPathAndName:
                    require("@/assets/images/javascript.png"),

                typeScriptIconPathAndName:
                    require("@/assets/images/typescript.png"),

                typeScriptQuiz:
                    require("@/assets/quizzes/typescript-miscellaneous-quiz.json"),
            };
        }
    }
